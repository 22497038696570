//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TuoShiLOGO from "../../components/TuoShiLOGO.vue";
export default {
  components: { TuoShiLOGO },
  data () {
    return {
      fileList: [],
      img: require('../../assets/我的账户.png'),
      username: "",
      phone: "",
      value: '',
    };
  },
  methods: {
    imgUp (e) {
      this.img = e.content
    },
  },
};
